import { configureStore } from '@reduxjs/toolkit';
import wappReducer from './features/wappSlice';

const store = configureStore({
  reducer: {
    wapp: wappReducer
  }
});

export default store;
