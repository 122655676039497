import { useState, createContext } from 'react';

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {

  const [sidebarToggle, setSidebarToggle] = useState(false);

  const toggleSidebar = () => {

    setSidebarToggle(!sidebarToggle);

  };
  const closeSidebar = () => {

    setSidebarToggle(false);

  };

  return (

    <SidebarContext.Provider

      value={{ sidebarToggle, toggleSidebar, closeSidebar }}

    >

      {children}
      
    </SidebarContext.Provider>
  );
};
