import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  lighten,
  darken
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
// import AugmentLogo from '../../../assets/svgIcons/AugmentLogo.svg';
import Logo from '../../../assets/Logo.svg';
import { Link } from 'react-router-dom';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 94vh;
        // height: calc(100vh - ${theme.header.height});
        padding-bottom: 8vh;
        background-color: ${theme.colors.alpha.white[100]};
        box-shadow: 0px 0px 10px 0px #0000000D;

`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          margin: '2vh 6vw 2vh 1vw',
          // margin: {
          //   xs: '5px 0px 0px 5px',
          //   md: '15px 5px 5px 10px',
          //   lg: '30px 10px 10px 20px'
          // },
          borderRadius: '10px',
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.white[100]),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Box sx={{ display: 'flex', height: 80, justifyContent: 'center' }}>
          <Link to="/">
            <img
              src={Logo}
              alt="Wappbiz Logo"
              style={{ height: '70px', alignItems: 'center', marginTop: '3px' }}
            />
          </Link>
        </Box>
        <Divider
          sx={{
            color: '#000000',
            margin: 0
          }}
        />
        <Scrollbar>
          <Divider
            sx={{
              mt: theme.spacing(1),
              mx: theme.spacing(1),
              // mb:theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <Box mt={1} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              width: 52,
              height: 80
            }}
          >
            <Link to="/">
              
              <img
                src={Logo}
                alt="Wappbiz Logo"
                style={{
                  height: '60px',
                  alignItems: 'center',
                  marginTop: '3px'
                }}
              />
            </Link>
          </Box>
        </Box>
        <Divider
          sx={{
            color: '#000000'
          }}
        />
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.white[100])
          }}
        >
          <Scrollbar>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
