import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  styled,
} from '@mui/material';

import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import profileAvatar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/profileUser.svg';
import changePassword from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/changePassword.svg';
import signOutAvatar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/signOut.svg';
import notificationIcon from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/notification.svg';
import quickGuide from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/quickGuide.svg';
import support from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/support.svg';
import calendar from 'src/assets/CustomerPageMedias/cutomerTopbarDropdown/calendar.svg';
import { useAuth } from 'src/Authentication/AuthContext';
import { useDispatch } from 'react-redux';
import { resetState } from 'src/redux/features/wappSlice';
import { getDecryptedData } from 'src/utility/LocalStorageManager';
import { useTheme } from '@emotion/react';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(1)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)`
  font-weight: 500;
  color: #111111;
  display: block;
  font-size: 15px;
`;

const UserBoxDescription = styled(Typography)`
  font-weight: 400;
  color: #a3a3a3;
  display: block;
  font-size: 12px;
`;

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#F6F6F6'
  }
}));

const apiKey = process.env.REACT_APP_API_URL;

function HeaderUserbox() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { logout, profile } = useAuth();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const getUserInfo = () => {
    return getDecryptedData('userInfo');
  };

  const userInfo = getUserInfo();

  useEffect(() => {
    getUserInfo();
  }, [profile]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMyProfileClick = () => {
    navigate('/profile');
    handleClose();
  };

  const handleChangePassword = () => {
    navigate('/profile');
    handleClose();
  };

  const handleOpenNotifications = () => {
    navigate('/notifications');
    handleClose();
  };

  const handleLogout = () => {
    logout();
    dispatch(resetState());

    // sessionStorage.removeItem('token')
    navigate('/login');
  };

  const handleOpenDocs = () => {
    window.open('https://docs.wapp.biz/', '_blank', 'noopener,noreferrer');
    handleClose();
  };
  const handleCalendar = async () => {
    navigate('/calendar');
    handleClose();
  };

  const handleSupport = () => {
    navigate('/support');
    handleClose();
  };

  return (
    <>
      {/* <Box sx={{alignContent:'center',mr:'5px'}}>
      <img src={notification} alt="notification icon"/>
    </Box> */}
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="circular"
          src={`${apiKey}${userInfo.profilePhoto}`}
          alt={userInfo.name}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel>{userInfo.name}</UserBoxLabel>
            <UserBoxDescription>{userInfo.email}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        // sx={{ marginTop: '4.4%' }}
        // sx={{
        //   marginTop: '4.4%',
        //     // [theme.breakpoints.down('sm')]: {
        //     //   right: '2vw', // Additional styling for xs screens
        //     //   marginTop: '8vh',
        //     // },
        // }}
        sx={{
          marginTop: '4.4%',
          [theme.breakpoints.down('xs')]: {
            marginLeft: '40px', // Specific for extra-small devices
            marginTop: '50px',
          },
          [theme.breakpoints.between('xs', 'sm')]: {
            marginLeft: '40px', // Adjusted for small devices
            marginTop: '60px',
          },
          [theme.breakpoints.up('sm')]: {
            marginLeft: '40px', // Adjusted for medium devices
            marginTop: '60px',
          },
          [theme.breakpoints.up('md')]: {
            marginLeft: '40px', // Adjusted for medium devices
            marginTop: '60px',
          },
          [theme.breakpoints.up('lg')]: {
            marginLeft: '5px', // Adjusted for large devices
            marginTop: '65px',
          }
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <StyledListItemButton onClick={handleMyProfileClick}>
            {/* <AccountBoxTwoToneIcon sx={{ mr: 1 }} fontSize="small" /> */}
            <img
              src={profileAvatar}
              style={{ height: '15px' }}
              alt={userInfo.name}
            />
            <ListItemText sx={{ ml: 1 }} primary="My Profile" />
          </StyledListItemButton>
          <StyledListItemButton onClick={handleOpenNotifications}>
            {/* <LockResetIcon sx={{ mr: 1 }} fontSize="small" /> */}
            <img
              src={notificationIcon}
              style={{ height: '16px' }}
              alt="NotificationIcon"
            />
            <ListItemText sx={{ ml: 1 }} primary="Notifications" />
          </StyledListItemButton>
          <StyledListItemButton onClick={handleChangePassword}>
            {/* <LockResetIcon sx={{ mr: 1 }} fontSize="small" /> */}
            <img
              src={changePassword}
              style={{ height: '15px' }}
              alt="ChangePasswordIcon"
            />
            <ListItemText sx={{ ml: 1 }} primary="Change Password" />
          </StyledListItemButton>

          <StyledListItemButton onClick={handleOpenDocs}>
            {/* <LockResetIcon sx={{ mr: 1 }} fontSize="small" /> */}
            <img
              src={quickGuide}
              style={{ height: '15px' }}
              alt="QuickGuideIcon"
            />
            <ListItemText sx={{ ml: 1 }} primary="Quick Guide" />
          </StyledListItemButton>

          {/* For mobile screen  */}
          <Hidden smUp>
            <StyledListItemButton onClick={handleCalendar}>
              {/* <LockResetIcon sx={{ mr: 1 }} fontSize="small" /> */}
              <img
                src={calendar}
                style={{ height: '15px' }}
                alt="calendarIcon"
              />
              <ListItemText sx={{ ml: 1 }} primary="Calendar" />
            </StyledListItemButton>
            <StyledListItemButton onClick={handleSupport}>
              {/* <LockResetIcon sx={{ mr: 1 }} fontSize="small" /> */}
              <img src={support} style={{ height: '15px' }} alt="supportIcon" />
              <ListItemText sx={{ ml: 1 }} primary="Support" />
            </StyledListItemButton>
          </Hidden>
        </List>
        <Divider />
        <List sx={{ p: 1 }} component="nav">
          <StyledListItemButton onClick={handleLogout}>
            {/* <LockResetIcon color="#dc2626" sx={{ mr: 1, color:'#dc2626' }} fontSize="small" /> */}
            <img
              src={signOutAvatar}
              style={{ height: '15px' }}
              alt={userInfo.name}
            />
            <ListItemText
              color="#dc2626"
              sx={{ color: '#dc2626', fontWeight: 600, ml: 1 }}
              primary="Sign out"
            />
          </StyledListItemButton>
        </List>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
