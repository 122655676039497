/* eslint-disable camelcase */
/* eslint-disable no-undef */
import { axiosInstance } from './axios';

// const excelExport = async (data) => {
//   try {
//     const response = await axiosInstance.post('users/importCustomer', {
//       excelData: data
//     });
//     return response.data;
//   } catch (error) {
//     return null;
//   }
// };
const exportExcel = async (formData, selectedTag) => {
  try {
    if (selectedTag) {
      // Append tags to the formData
      formData.append('tags', JSON.stringify(selectedTag));
    }
    console.log(formData, 'formData after');

    const response = await axiosInstance.post(
      'customers/importCustomers',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listAllCustomers = async (id) => {
  try {
    const response = await axiosInstance.get(
      'customers/listAllCustomers?id=' + id
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listAllUsers = async () => {
  try {
    const response = await axiosInstance.get('user/all_users');

    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listAllCustomersWithChat = async (page) => {
  try {
    const response = await axiosInstance.get(
      'customers/listAllCustomersWithChat',
      {
        params: {
          page
        }
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const changeAssignee = async (id, userId) => {
  try {
    const response = await axiosInstance.post('customers/change_assignee', {
      id,
      userId
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

const createSegments = async (name, filteredData) => {
  try {
    const response = await axiosInstance.post('customers/createSegments', {
      segmentName: name,
      filters: filteredData
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listSegments = async () => {
  try {
    const response = await axiosInstance.get('customers/listSegments');
    return response.data.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listSegmentsWithDetails = async () => {
  try {
    const response = await axiosInstance.get('customers/list_segments');
    return response.data.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const sendMessage = async (data) => {
  try {
    const response = await axiosInstance.post('message/send_message', data);
    return response;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listMessages = async (customerId, page) => {
  try {
    const response = await axiosInstance.get(
      `message/list_messages/${customerId}`,
      {
        params: {
          page
        }
      }
    );
    return response;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};
const listSegmentsById = async (segmentId) => {
  try {
    const response = await axiosInstance.get(
      `customers/listSegmentDataById?segmentId=${segmentId}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listSegmentDataWithDetailsById = async (segmentId) => {
  try {
    const response = await axiosInstance.get(
      `customers/list_segments_data?segmentId=${segmentId}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listAllSegmentDataWithDetails = async () => {
  try {
    const response = await axiosInstance.get(
      `customers/list_all_segments_data`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listCampaignData = async (campaignId) => {
  try {
    const response = await axiosInstance.get(
      `customers/list_campaign?campaignId=${campaignId}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listCampaignDataWithDetailsById = async (campaignId) => {
  try {
    const response = await axiosInstance.get(
      `customers/list_campaign_data?campaignId=${campaignId}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const sendMediaOld = async (formdata) => {
  try {
    const response = await axiosInstance.post('media/upload_media', formdata);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

// const sendMedia = async (formData, onProgress) => {
//   try {
//     const response = await axiosInstance.post('media/upload_media', formData, {
//       headers: { 'Content-Type': 'multipart/form-data' },
//       onUploadProgress: (progressEvent) => {
//         console.log('Inside onUploadProgress', progressEvent);
//         if (progressEvent) {
//           const percentCompleted = Math.round(
//             (progressEvent.loaded * 100) / progressEvent.total
//           );
//           onProgress(percentCompleted);
//         }
//       }
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error sending media:', error.message);
//     throw new Error(error.message || 'Failed to upload media');
//   }
// };

const sendMedia = async (formData, onProgress) => {
  try {
    const response = await axiosInstance.post('media/upload_media', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        if (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress(percentCompleted);
        }
      },
      timeout: 10000 // 10 seconds timeout
    });
    return response.data;
  } catch (error) {
    // if (axios.isCancel(error)) {
    //   console.log('Request canceled:', error.message);
    // } else {
    console.error('Error sending media:', error.message);
    throw new Error(error.response?.statusText || 'Failed to upload media');
    // }
  }
};

const listMedia = async () => {
  try {
    const response = await axiosInstance.get('media/get_all_media');
    return response?.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deleteMedia = async (mediaId) => {
  try {
    const response = await axiosInstance.delete(
      `media/delete_media?mediaId=${mediaId}`
    );
    return response?.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const editUserProfile = async (name, email, location, currency, gstNumber) => {
  try {
    const response = await axiosInstance.post('user/edit_profile', {
      name,
      email,
      location,
      currency,
      gstNumber
    });
    return response?.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const uploadProfilePhoto = async (formdata) => {
  try {
    const response = await axiosInstance.post('user/upload_media', formdata);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const fetchUserData = async () => {
  try {
    const response = await axiosInstance.get('user/fetch_user_data');
    return response;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const changePassword = async (oldPassword, newPassword) => {
  try {
    const response = await axiosInstance.post('user/change_password', {
      oldPassword,
      newPassword
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listRoles = async () => {
  try {
    const response = await axiosInstance.get('role/list_roles');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addUser = async (name, phone, email, selectedRole, hashedPassword) => {
  try {
    const response = await axiosInstance.post('user/add_user', {
      name,
      phone,
      email,
      selectedRole,
      hashedPassword
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listBroadcasts = async (customerId) => {
  try {
    const response = await axiosInstance.get(
      'customers/get_broadcasts?customerId=' + customerId
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getDashboardData = async () => {
  try {
    const response = await axiosInstance.get('dashboard/analytics');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addCustomer = async (data) => {
  try {
    const response = await axiosInstance.post('customers/add_customer', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const editTeamMembers = async ({ _id, name, email, phone, role_id }) => {
  try {
    const response = await axiosInstance.post('user/edit_team_member', {
      _id,
      name,
      email,
      phone,
      role_id
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const changeTeamPassword = async (id, password) => {
  console.log(id, password, 'id, password');
  try {
    const response = await axiosInstance.post('user/change_team_pass', {
      id,
      password
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deleteTeamMember = async (id) => {
  try {
    const response = await axiosInstance.post('user/delete_user', id);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deleteCustomer = async (customerId) => {
  // console.log(customerId);
  try {
    const response = await axiosInstance.post(
      'customers/delete_customer',
      customerId
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

//  delete broadcast ( old segment now broadcast)
const deleteBroadcast = async (broadcastId) => {
  // console.log(broadcastId);
  try {
    const response = await axiosInstance.post(
      'customers/delete_broadcast',
      broadcastId
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

//  delete Campaign (Means broadcast message with the profile image and the name)
const deleteCampaign = async (campaignId) => {
  // console.log(campaignId);
  try {
    const response = await axiosInstance.post(
      'customers/delete_campaign',
      campaignId
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const createBroadcast = async (data) => {
  try {
    const response = await axiosInstance.post(
      'customers/create_broadcast',
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const bulkDeletion = async (data) => {
  try {
    const response = await axiosInstance.post('customers/bulk_delete', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const sendMediaMessage = async (data) => {
  try {
    const response = await axiosInstance.post(
      'message/send_media_message',
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listPlans = async () => {
  try {
    const response = await axiosInstance.get('plan/fetchPlans');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listTags = async () => {
  try {
    const response = await axiosInstance.get('tags/list_tags');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listTagsById = async (tags) => {
  try {
    const response = await axiosInstance.post('tags/list_tags', tags);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addTag = async (data) => {
  try {
    const response = await axiosInstance.post('tags/add_tag', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const editTag = async (data) => {
  try {
    const response = await axiosInstance.put(`tags/edit_tag`, data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deleteTag = async (data) => {
  try {
    const response = await axiosInstance.delete(`tags/delete_tag?id=${data}`);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listCategory = async () => {
  try {
    const response = await axiosInstance.get('tags/list_categories');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addCategory = async (data) => {
  try {
    const response = await axiosInstance.post('tags/add_category', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const removeTagFromCustomer = async (data) => {
  try {
    const response = await axiosInstance.post('customers/remove_tag', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addTagToCustomer = async (data) => {
  try {
    const response = await axiosInstance.post('customers/add_tag', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const fetchCustomerById = async (customerId) => {
  try {
    const response = await axiosInstance.get(
      'customers/fetch_customer_by_id?customerId=' + customerId
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const sendFirebaseToken = async (token) => {
  // console.log(token, 'token');
  try {
    const response = await axiosInstance.post('firebase/storeToken', { token });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const editCustomerById = async (data) => {
  try {
    const response = await axiosInstance.put('customers/edit_customer', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const bulkTagging = async (data) => {
  try {
    const response = await axiosInstance.post('customers/bulk_tagging', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const registerBusiness = async (data) => {
  try {
    const response = await axiosInstance.post(
      'business_register/register',
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getRegisteredBusiness = async () => {
  try {
    const response = await axiosInstance.get(
      'whatsapp_business_details/listActiveBusinessRegisteredProfileData'
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addBusinessToDatabase = async (data) => {
  try {
    const response = await axiosInstance.post(
      'business_register/add_register_business',
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

// const sendSupportMail = async (supportType, message) => {
//   try {
//     console.log(supportType, message,'supportType, message')
//     const response = await axiosInstance.post(
//       'user/send_support_mail',
//       {supportType, message}
//     );
//     return response.data;
//   } catch (error) {
//     console.log(error.message);
//     return null;
//   }
// };

const sendSupportMail = async (file, supportType, message) => {
  try {
    console.log(supportType, message, 'supportType, message');

    // Create an instance of FormData to handle file uploads
    const formData = new FormData();
    formData.append('supportType', supportType);
    formData.append('message', message);

    // Append files to formData if any
    if (file) {
      formData.append(`file`, file);
    }

    const response = await axiosInstance.post(
      'user/send_support_mail',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data' // Important for files
        }
      }
    );
    return response.data;
  } catch (error) {
    console.log('Error sending email:', error.message);
    return null;
  }
};

const getPermissions = async () => {
  try {
    const response = await axiosInstance.get('notification/get_permissions');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const updateWebNotifications = async (option) => {
  try {
    const response = await axiosInstance.post(
      'notification/update_webNotification',
      option
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const updateEmailNotifications = async (option) => {
  try {
    const response = await axiosInstance.post(
      'notification/update_emailNotification',
      option
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const sendForgetPasswordEmail = async (email) => {
  try {
    const response = await axiosInstance.post('auth/forget-pass-email', email);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const sendRegisterEmail = async (email) => {
  try {
    const response = await axiosInstance.post('auth/register-biz-email', email);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const verifyOTP = async (data) => {
  try {
    const response = await axiosInstance.post('auth/verify-otp', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const ChangeForgetPassword = async (data) => {
  try {
    const response = await axiosInstance.post('auth/forget-password', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const fetchPlanHistory = async () => {
  try {
    const response = await axiosInstance.get('plan/fetchPlanHistory');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const fetchCreditHistory = async () => {
  try {
    const response = await axiosInstance.get('plan/fetchCreditHistory');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const VerifyRegister = async (pin) => {
  try {
    const response = await axiosInstance.post(
      'business_register/two-factor',
      pin
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const fetchCampaignCustomers = async (campaignId) => {
  try {
    const response = await axiosInstance.get(
      `customers/campaignCustomers?campaignId=${campaignId}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getOptOut = async () => {
  try {
    const response = await axiosInstance.get('customers/get_opt_out');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listAllFlows = async () => {
  try {
    const response = await axiosInstance.get('flow/listFlow');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listAllGlobalForms = async () => {
  try {
    const response = await axiosInstance.get('flow/listGlobalFlow');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const publishFlow = async (flowID) => {
  try {
    const response = await axiosInstance.post('flow/publishFlow', {
      flowID
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const createQRCode = async (
  ctaButtonText,
  ctaButtonColor,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  cornerRadius,
  prefillMessageChatButton,
  position,
  widgetHeadingText,
  widgetButtonText,
  widgetButtonColor,
  prefillMessageChatWidget,
  imageUrl,
  profileURL
) => {
  try {
    const response = await axiosInstance.post(
      'whatsapp_business_details/getQRCode',
      {
        ctaButtonText,
        ctaButtonColor,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
        cornerRadius,
        prefillMessageChatButton,
        position,
        widgetHeadingText,
        widgetButtonText,
        widgetButtonColor,
        prefillMessageChatWidget,
        imageUrl,
        profileURL
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listAllQRCodes = async () => {
  try {
    const response = await axiosInstance.get(
      'whatsapp_business_details/listAllQRCodes'
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deleteQRCode = async (qrCodeId) => {
  try {
    const response = await axiosInstance.post(
      `whatsapp_business_details/deleteQRCode`,
      { qrCodeId }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};
const listAllCatalogues = async () => {
  try {
    const response = await axiosInstance.get('catalog/get-catalog');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addCatalogue = async (data) => {
  try {
    const response = await axiosInstance.post('catalog/create-catalog', data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deleteCatalogue = async (data) => {
  try {
    const response = await axiosInstance.delete(
      `catalog/delete-catalog?catalog_id=${data}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const associateCatalogue = async (data) => {
  try {
    const response = await axiosInstance.post(
      `catalog/associate-catalog`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deassociateCatalogue = async (data) => {
  try {
    const response = await axiosInstance.post(
      `catalog/deassociate-catalog`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getProductsByCatalogId = async (data) => {
  try {
    const response = await axiosInstance.get(
      `catalog/get-products?catalog_id=${data}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addProductsToCatalog = async (data) => {
  try {
    const response = await axiosInstance.post(`catalog/add-products`, data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const createProductSet = async (data) => {
  try {
    const response = await axiosInstance.post(
      `catalog/create-productset`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deleteProducts = async (data) => {
  try {
    const response = await axiosInstance.post(`catalog/delete-product`, data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listFlowFormResponses = async () => {
  try {
    const response = await axiosInstance.get(`flow/getFlowReponses`);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getAllCalendarEvents = async () => {
  try {
    const response = await axiosInstance.get(`calendar/getAllEvents`);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const addEvents = async (eventName, date) => {
  try {
    const response = await axiosInstance.post(`calendar/addEvent`, {
      eventName,
      date
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const formsHaveResponses = async () => {
  try {
    const response = await axiosInstance.get('/flow/formsHaveResponses');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const downloadFormResponses = async (flowId) => {
  try {
    const response = await axiosInstance.get(
      `/flow/downloadFormResponses/${flowId}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const checkAvailability = async (customerId) => {
  try {
    const response = await axiosInstance.get(
      `message/checkCustomerWindow/${customerId}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const ApiCalls = {
  addCustomer,
  listAllUsers,
  changeAssignee,
  listBroadcasts,
  listAllCustomers,
  listAllCustomersWithChat,
  listCampaignData,
  createSegments,
  listSegments,
  exportExcel,
  sendMessage,
  listMessages,
  listSegmentsById,
  sendMedia,
  listMedia,
  deleteMedia,
  editUserProfile,
  uploadProfilePhoto,
  fetchUserData,
  changePassword,
  listRoles,
  addUser,
  editTeamMembers,
  changeTeamPassword,
  deleteTeamMember,
  getDashboardData,
  listSegmentsWithDetails,
  listSegmentDataWithDetailsById,
  listAllSegmentDataWithDetails,
  listCampaignDataWithDetailsById,
  deleteCustomer,
  deleteBroadcast,
  deleteCampaign,
  createBroadcast,
  bulkDeletion,
  sendMediaMessage,
  listPlans,
  sendFirebaseToken,
  listTags,
  listTagsById,
  addTag,
  editTag,
  deleteTag,
  listCategory,
  addCategory,
  removeTagFromCustomer,
  addTagToCustomer,
  fetchCustomerById,
  sendFirebaseToken,
  editCustomerById,
  bulkTagging,
  registerBusiness,
  getRegisteredBusiness,
  addBusinessToDatabase,
  sendSupportMail,
  getPermissions,
  updateWebNotifications,
  updateEmailNotifications,
  sendForgetPasswordEmail,
  ChangeForgetPassword,
  fetchPlanHistory,
  fetchCreditHistory,
  VerifyRegister,
  fetchCampaignCustomers,
  sendRegisterEmail,
  verifyOTP,
  getOptOut,
  listAllFlows,
  listAllGlobalForms,
  publishFlow,
  createQRCode,
  listAllQRCodes,
  deleteQRCode,
  listAllCatalogues,
  addCatalogue,
  deleteCatalogue,
  associateCatalogue,
  deassociateCatalogue,
  getProductsByCatalogId,
  addProductsToCatalog,
  createProductSet,
  deleteProducts,
  listFlowFormResponses,
  sendMediaOld,
  getAllCalendarEvents,
  addEvents,
  downloadFormResponses,
  formsHaveResponses,
  checkAvailability
};
export default ApiCalls;
