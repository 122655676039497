import { axiosInstance } from './axios';

const getAnalytics = async (start, end) => {
  try {
    const response = await axiosInstance.get(
      `analytics/getAnalytics?start=${start}&end=${end}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getTopRatedTeamMembers = async (start, end, limit) => {
  try {
    const response = await axiosInstance.get(
      `analytics/getTopRatedTeamMembers?start=${start}&end=${end}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getConversationDataForPieChart = async (start, end) => {
  try {
    const response = await axiosInstance.get(
      `analytics/getConversationAnalytics?start=${start}&end=${end}`
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const updateWalletBalance = async (amount) => {
  try {
    const response = await axiosInstance.put(
      `business_register/update_business_balance/${amount}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const unreadChatCounts = async () => {
  try {
    const response = await axiosInstance.get('analytics/newMessagesCount');
    // console.log(response.data.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getNotifications = async (limit) => {
  try {
    const response = await axiosInstance.get(
      `notification/all_notifications?limit=${limit}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getUnreadNotificationCount = async () => {
  try {
    const response = await axiosInstance.get(
      `notification/unread_notification`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const checkAllNotifications = async () => {
  try {
    const response = await axiosInstance.post(
      `notification/change_notification_status`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const usedMessageQuota = async () => {
  try {
    const response = await axiosInstance.get(`analytics/usedMessageQuota`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const freeTierConversations = async () => {
  try {
    const response = await axiosInstance.get(`analytics/freeTierConversations`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};


const getSevenDaysSendMessageAnalytics = async () => {
  try {
    const response = await axiosInstance.get(
      `analytics/getSevenDaysSendMessageAnalytics`
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getTotalPaidMessageCountForGraph = async () => {
  try {
    const response = await axiosInstance.get(
      `analytics/getTotalPaidMessageCountForGraph`
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getFunnelData = async () => {
  try {
    const response = await axiosInstance.get(`analytics/getFunnelData`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getTotalPaidAmountForCampaigns = async () => {
  try {
    const response = await axiosInstance.get(
      `analytics/getTotalPaidAmountForCampaigns`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const AnalyticsApiCalls = {
  getAnalytics,
  getTopRatedTeamMembers,
  getConversationDataForPieChart,
  updateWalletBalance,
  unreadChatCounts,
  getNotifications,
  getUnreadNotificationCount,
  checkAllNotifications,
  usedMessageQuota,
  getSevenDaysSendMessageAnalytics,
  getTotalPaidMessageCountForGraph,
  getFunnelData,
  getTotalPaidAmountForCampaigns,
  freeTierConversations
};
export default AnalyticsApiCalls;
