import React, { useContext } from 'react';
import {
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography,
  Badge,
  alpha
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import dashboardIcon from 'src/assets/AdminPageMedias/SIdebarIcon/DashboardIcon.svg';
import customerIcon from 'src/assets/AdminPageMedias/SIdebarIcon/CustomersIcon.svg';
import supportIcon from 'src/assets/AdminPageMedias/SIdebarIcon/SupportIcon.svg';
import plansIcon from 'src/assets/AdminPageMedias/SIdebarIcon/PlansIcon.svg';
import businessIcon from 'src/assets/AdminPageMedias/SIdebarIcon/AffiliateIcon.svg';
// import Analytics from '../../../../assets/svgIcons/Analytics.svg';
// import ManageIcon from '../../../../assets/svgIcons/ManageIcon.svg';
// import ChatbotIcon from '../../../../assets/svgIcons/chatbotIcon.svg';
// import FormIcon from 'src/assets/ManagePageIcons/FormIcon.svg'
// import CatalogIcon from 'src/assets/ManagePageIcons/Catalogue.svg'
// flowForms

import { useSelector } from 'react-redux';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)} !important;
        color: ${theme.palette.error.main} !important;
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.colors.alpha.white[100]};
  .MuiList-root {
    padding:0px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0px;
        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};
          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.black[100]};
          background-color: transparent;
          width: 75px;
          height: 75px;
          justify-content: center;
          padding: ${theme.spacing(1.2, 1.2)};
          transition: background-color ${theme.transitions.duration.short}ms;

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.h1.fontSize};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(15)};
          }

          &:hover {
            background-color: #BEFCC5;
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }

          &.active {
            background-color: #27AB69;
            color: ${theme.colors.alpha.white[100]};            
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }

          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 5px;
                height: 5px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const StyledButton = styled(Button)`
  && {
    border-radius: 20px;
    margin-bottom: 3px;
    background-color: #ffffff; /* Add your desired background color */
    // transition: background-color 0.3s ease; /* Add transition effect for hover */
    padding: 8px; /* Adjust padding as needed */
    &:hover {
      background-color: #f0f0f0; /* Add hover background color */
    }
    & img {
      width: 18px;
      height: 18px;
      filter: brightness(0) invert(0);
    }
    &.active img {
      filter: brightness(0) invert(1);
    }
  }
`;

const IconHeading = styled(Typography)`
  text-align: center;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  margin-top: 5px;
`;

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  // const { unreadChatCounts,businessProfileData, isRegister } = useSelector((state) => state.wapp);

  // console.log(isRegister);

  const menuItems = [
    { to: '/admin/dashboard', icon: dashboardIcon, label: 'Dashboard' },
    { to: '/admin/businesses', icon: businessIcon, label: 'Businesses' },
    { to: '/admin/customers', icon: customerIcon, label: 'Customers' },
    { to: '/admin/support', icon: supportIcon, label: 'Support' },
    { to: '/admin/plans', icon: plansIcon, label: 'Plans' },
    { to: '/admin/chatbot', icon: supportIcon, label: 'Chatbot' },
    { to: '/admin/currency', icon: supportIcon, label: 'Currency' },
    { to: '/admin/campaign', icon: supportIcon, label: 'Campaign' },
    


    
    // {
    //   to: '/chats',
    //   icon: chatIcon,
    //   label: 'Chats',
    //   badgeContent: unreadChatCounts
    // },
    // { to: '/AllCampaigns', icon: AllCampaign, label: 'Campaigns' },
    // // { to: '/broadcasts', icon: broadcastIcon, label: 'Broadcast' },
    // { to: '/customers', icon: customerIcon, label: 'Customers' },
    // { to: '/templates', icon: templateIcon, label: 'Templates' },
    // { to: '/chatbot', icon: ChatbotIcon, label: 'Chatbot' },
    // { to: '/forms', icon: FormIcon, label: 'Forms' },
    // { to: '/manage', icon: ManageIcon, label: 'Manage' },
    // { to: '/catalogue', icon: CatalogIcon, label : 'Catalog'}
  ];

  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            {menuItems.map(({ to, icon, label, badgeContent }) => (
              <ListItem component="div" key={label}>
                <StyledButton
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={to}
                  activeclassname="active"
                  // className={businessProfileData?.isRegistered === false || isRegister === 0 && to !== '/' ? 'disabled' : ''}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <img src={icon} alt={`${label} Icon`} />
                    <IconHeading>{label}</IconHeading>
                    {badgeContent > 0 && (
                      <NotificationsBadge
                        badgeContent={badgeContent}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      />
                    )}
                  </Box>
                </StyledButton>
              </ListItem>
            ))}
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;
