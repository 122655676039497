import ApiCalls from 'src/utility/ApiCalls';
import AnalyticsApiCalls from 'src/utility/AnalyticsApiCalls';
import { addAction, removeAction } from '../features/wappSlice';
import BusinessApiCalls from 'src/utility/BusinessApiCalls';
import RazorPayApiCalls from 'src/utility/RazorPayApiCalls';

export const getRegisteredBusiness = async (dispatch) => {
  try {
    const response = await ApiCalls.getRegisteredBusiness();
    if (response?.data.length !== 0) {
      dispatch(addAction({ payload: response?.data[0], type: 'businessProfileData' }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUnreadChatCounts = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.unreadChatCounts();
    if (response?.data) {
      // console.log(response, 'response  redux');
      // dispatch(addAction(response?.data, 'newMessages'));
      dispatch(addAction({ payload: response.data, type: 'unreadChatCount' }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUnreadNotification = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.getUnreadNotificationCount();
    if (response?.data) {
      // console.log(response, 'response notifications redux');
      dispatch(
        addAction({ payload: response?.data, type: 'unreadNotification' })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const getBusinessProfileData = async (dispatch) => {
  try {
    const response = await BusinessApiCalls.fetchBusinessProfileData();
    if (response?.data?.length > 0) {
      // console.log(response?.data, 'businessProfileData');
      dispatch(
        addAction({ payload: response?.data[0], type: 'business' })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUsedMessageQuota = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.usedMessageQuota();
    if (response) {
      console.log(
        response?.data?.totalDistinctMessages,
        'totalDistinctMessages'
      );
      dispatch(
        addAction({
          payload: response?.data?.totalDistinctMessages,
          type: 'usedMessageQuota'
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const getFreeTierConversations = async (dispatch) => {
  try {
    const response = await AnalyticsApiCalls.freeTierConversations();
    if (response) {
      console.log(
        response?.data?.count,
        'freeTierConversations'
      );
      dispatch(
        addAction({
          payload: response?.data?.count,
          type: 'freeTierConversations'
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

// export const getUserPermissions = async (dispatch) => {
//   try {
//     const response = await ApiCalls.getPermissions();
//     console.log(response);
//     if (response?.data.length > 0) {
//       dispatch(addAction({ payload: response?.data, type: 'permission' }));
//     }
//   } catch (error) {
//     console.log(error.message);
//   }
// }

export const getSubscriptionExists = async (dispatch) => {
  try {
    const response = await RazorPayApiCalls.getSubscriptionExists();
    if (response?.data) {
      console.log(response, 'response subscriptionsCounts redux');
      dispatch(
        addAction({ payload: response?.data, type: 'subscriptionsCounts' })
      );
    }
  } catch (error) {
    console.log(error);
  }
};
