import { axiosInstance } from './axios';

const createOrder = async (amount,currency) => {
  try {
    const response = await axiosInstance.post('razorpay/orders', { amount,currency });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const successOrder = async (
  orderCreationId,
  razorpayPaymentId,
  razorpayOrderId,
  razorpaySignature,
  amount,
  currency
) => {
  try {
    const response = await axiosInstance.post('razorpay/success', {
      orderCreationId,
      razorpayPaymentId,
      razorpayOrderId,
      razorpaySignature,
      amount,
      currency
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const createSubscription = async ({ planId }) => {
  try {
    const response = await axiosInstance.post('razorpay/createSubscription', {
      planId
      // customerId
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const successSubscription = async (
  razorpayPaymentId,
  razorpaySignature,
  razorpaySubscriptionId,
  planId
) => {
  try {
    const response = await axiosInstance.post('razorpay/successSubscription', {
      razorpayPaymentId,
      razorpaySignature,
      razorpaySubscriptionId,
      planId
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const cancelSubscription = async () => {
  try {
    const response = await axiosInstance.get('razorpay/cancelSubscription');
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const FreeTrialSubscription = async (planId) => {
  try {
    const response = await axiosInstance.post('razorpay/freeTrialSubscription', {
      planId
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

const getSubscriptionExists = async()=>{
  try {
    const response = await axiosInstance.get(`razorpay/subscriptionExists`)
    return response.data;
  } catch (error) {
    return error.message;
  }
}

const RazorPayApiCalls = {
  createOrder,
  successOrder,
  createSubscription,
  successSubscription,
  cancelSubscription,
  FreeTrialSubscription,
  getSubscriptionExists
};
export default RazorPayApiCalls;
