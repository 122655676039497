
import CryptoJS from 'crypto-js';

// const secretKey = '12345678901234567890123456789012'; // Replace with your actual secret key (must be 32 bytes)
const secretKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
console.log(secretKey,'secretKey');


// Function to encrypt data and save it to local storage
export const storeEncryptedData = (key, data) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  localStorage.setItem(key, encryptedData);
};

// Function to retrieve data from local storage and decrypt it
export const getDecryptedData = (key) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // console.log(decryptedData,'decryptedData decryptedData');
    
    return decryptedData;
  }
  return null;
};

// Function to remove data from local storage
export const removeEncryptedData = (key) => {
    localStorage.removeItem(key);
  };


















// // Import the native crypto module
// import crypto from 'crypto';

// const secretKey = '12345678901234567890123456789012'; // Replace with your actual secret key (must be 32 bytes)
// const iv = '1234567890123456'; // Replace with your actual IV (must be 16 bytes)

// // Function to encrypt data and save it to local storage
// export const storeEncryptedData = (key, data) => {
//   const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(secretKey, 'utf8'), Buffer.from(iv, 'utf8'));
//   let encryptedData = cipher.update(JSON.stringify(data), 'utf8', 'hex');
//   encryptedData += cipher.final('hex');
//   const encryptedPayload = JSON.stringify({
//     iv: iv.toString('hex'),
//     content: encryptedData,
//   });
//   localStorage.setItem(key, encryptedPayload);
// };

// // Function to retrieve data from local storage and decrypt it
// export const getDecryptedData = (key) => {
//   const encryptedData = localStorage.getItem(key);
//   if (encryptedData) {
//     const parsedData = JSON.parse(encryptedData);
//     const decipher = crypto.createDecipheriv(
//       'aes-256-cbc',
//       Buffer.from(secretKey, 'utf8'),
//       Buffer.from(parsedData.iv, 'hex')
//     );
//     let decryptedData = decipher.update(parsedData.content, 'hex', 'utf8');
//     decryptedData += decipher.final('utf8');
//     return JSON.parse(decryptedData);
//   }
//   return null;
// };



















// import CryptoJS from 'crypto-js';

// const secretKey = 'your-secret-key'; // Replace with your actual secret key

// // Function to encrypt data and save it to local storage
// export const storeEncryptedData = (key, data) => {
//   const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
//   localStorage.setItem(key, encryptedData);
// };

// // Function to retrieve data from local storage and decrypt it
// export const getDecryptedData = (key) => {
//   const encryptedData = localStorage.getItem(key);
//   if (encryptedData) {
//     const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
//     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//   }
//   return null;
// };

