import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  lighten,
  darken
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
// import AugmentLogo from '../../../assets/svgIcons/AugmentLogo.svg';
import Logo from '../../../assets/Logo.svg';
import { Link } from 'react-router-dom';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 98vh;
        // height: calc(100vh - ${theme.header.height});
        padding-bottom: 8vh;
        // background-color: ${theme.colors.alpha.white[100]};
        background-color: ${theme.wappBizColor.white};
        
        box-shadow: 0px 0px 10px 0px #0000000D;

`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          margin: '1.2vh 6vw 2vh 0.7vw',
          borderRadius: '10px',
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: theme.wappBizColor.white
        }}
      >
        <Box sx={{ display: 'flex', height: 70, justifyContent: 'center',backgroundColor:'#FAFAFA' }}>
          <Link to="/">
            <img
              src={Logo}
              alt="Wappbiz Logo"
              style={{ height: '65px', alignItems: 'center'}}
            />
          </Link>
        </Box>
        <Divider
          sx={{
            color: theme.wappBizColor.background,
            margin: 0,
            opacity: 0.5,
            borderTop: '1px solid',
          }}
        />
        <Scrollbar>
          <Divider
            sx={{
              mt: theme.spacing(1),
              mx: theme.spacing(1),
              background: theme.wappBizColor.white
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            color: '#000000'
          }}
        />
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
          color: theme.wappBizColor.white
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <Box
          // mt={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            background: theme.wappBizColor.white
          }}
        >
          <Box
            sx={{
              width: 52,
              height: 70,
              backgroundColor:'#FAFAFA'
            }}
          >
            <Link to="/">
              <img
                src={Logo}
                alt="Wappbiz Logo"
                style={{
                  height: '60px',
                  alignItems: 'center',
                }}
              />
            </Link>
          </Box>
        </Box>
        <Divider
          sx={{
            color: theme.wappBizColor.background,
            margin: 0,
            opacity: 0.5,
            borderTop: '1px solid',
          }}
        />
        <SidebarWrapper
          sx={{
            background: theme.wappBizColor.white
          }}
        >
          <Scrollbar>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
