import { useEffect, useState, createContext, useContext } from 'react';
import { messaging } from 'src/firebase-config';
import { deleteToken } from 'firebase/messaging';
import { getDecryptedData, removeEncryptedData, storeEncryptedData } from 'src/utility/LocalStorageManager';

const AuthContext = createContext();
// // Encrypt and store data
// const userData = { username: 'Parth', email: 'parth@example.com' };
// storeEncryptedData('user_data', userData);

// // Retrieve and decrypt data
// const decryptedUserData = getDecryptedData('user_data');
// console.log(decryptedUserData); // { username: 'Parth', email: 'parth@example.com' }
export const AuthProvider = ({ children }) => {
  const userInfo = 'userInfo';
  const adminInfo = 'adminInfo';
  const expirationKey = 'tokenExpiration';

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!getDecryptedData(userInfo)
  );
  // const decryptedUserData = getDecryptedData('user_data');
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(
    !!sessionStorage.getItem(adminInfo)
  );
  const [profile, setProfile] = useState(false);

  const login = (data, expiresIn) => {
    setIsAuthenticated(true);
    // sessionStorage.setItem(userInfo, JSON.stringify(data));
    // Encrypt and store data
    storeEncryptedData('userInfo', data);
    setProfile(!profile);

    // Calculate expiration timestamp (current time + expiration time)
    // const expirationTimestamp = Date.now() + expiresIn * 1000;
    // sessionStorage.setItem(expirationKey, expirationTimestamp.toString());
  };

  const adminLogin = (data, expiresIn) => {
    setIsAdminAuthenticated(true);
    sessionStorage.setItem(adminInfo, JSON.stringify(data));
    setProfile(!profile);

    // Calculate expiration timestamp (current time + expiration time)
    const expirationTimestamp = Date.now() + expiresIn * 1000;
    sessionStorage.setItem(expirationKey, expirationTimestamp.toString());
  };

  const logout = () => {
    setIsAuthenticated(false);
    // sessionStorage.removeItem(userInfo);
    // sessionStorage.removeItem(expirationKey);
    removeEncryptedData(userInfo)
    sessionStorage.removeItem('selectedPerson');

    deleteToken(messaging)
      .then(() => {
        console.log('Token deleted.');
      })
      .catch((err) => {
        console.log('Unable to delete token. ', err);
      });
  };

  const adminLogout = () => {
    setIsAdminAuthenticated(false);
    sessionStorage.removeItem(adminInfo);
    sessionStorage.removeItem(expirationKey);
  };

  // Check if the stored token has expired
  useEffect(() => {
    const expirationTimestamp = sessionStorage.getItem(expirationKey);
    if (expirationTimestamp && Date.now() > parseInt(expirationTimestamp, 10)) {
      // Token has expired, perform logout
      logout();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdminAuthenticated,
        login,
        adminLogin,
        logout,
        adminLogout,
        profile,
        setProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
