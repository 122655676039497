import { Box, Hidden } from '@mui/material';
// import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';
import Calendar from './Calendar';
import Support from './Support'

function HeaderButtons() {
  return (
    <Hidden smDown>
    <Box sx={{ mr: .5 }}>
      <Box sx={{ mx: '1px' }} component="span">
        <Calendar />
        {/* <HeaderNotifications /> */}
      </Box>
      <Box sx={{ mx: '1px' }} component="span">
        {/* <Calendar /> */}
        <Support />
      </Box>
      <Box sx={{ mx: '1px' }} component="span">
        {/* <Calendar /> */}
        <HeaderNotifications />
      </Box>
    </Box>
  </Hidden>
  );
}

export default HeaderButtons;
