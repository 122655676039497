import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import supportIcon from 'src/assets/CustomerPageMedias/customerTopBarIcons/support.svg'

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#333',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: '4px 16px' // Reduced padding
  }
}));

const index = () => {
    const ref = useRef(null);
    const navigate = useNavigate();
  const handleOpen = () => {
    navigate('/support');
  };

  return (
    <>
      <CustomTooltip arrow title="Support">
        <IconButton
          color="primary"
          ref={ref}
          onClick={handleOpen}
          sx={{ '&:hover': { background: 'transparent' } }}
        >
            <img src={supportIcon} alt="support" height='35px' />
        </IconButton>
      </CustomTooltip>
    </>
  );
};

export default index;
