import { Box ,useTheme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useEffect, useState } from 'react';

const SidebarLayout = () => {
  const location = useLocation();
  const theme = useTheme();
  const [selectedPage, setSelectedPage] = useState('');

  const updateSelectedPage = () => {
    const pathName = location.pathname.split('/')[2]; // Get the first part after the slash
    let selectedPage = '';

    selectedPage = pathName.charAt(0).toUpperCase() + pathName.slice(1);

    if(pathName == "")
      selectedPage = 'Dashboard';

    setSelectedPage(selectedPage);
  };

  useEffect(() => {
    updateSelectedPage();
  }, [location.pathname]);

  return (
    <>
      <Header selectedPage={selectedPage} />
      <Box
        sx={{
          flex: 1,
          height: '100%',
          // margin: '8vh 1vw 5vh 2vw'
          margin: {
            xs:'1vh 2vw 0vh 1vw',
            sm: '3vh 1vw 4vh 1vw',
            lg: '5vh 1vw 4vh 2vw'
          },
          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
          },
        }}
      >
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
