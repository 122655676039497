import { axiosInstance } from './axios';

const fetchNumberDisplayName = async () => {
  try {
    const response = await axiosInstance.get(
      `whatsapp_business_details/getdisplay_name`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const fetchBusinessProfileData = async (id) => {
  try {
    const response = await axiosInstance.get(
      'business_register/get_registered_business'
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const updateBusinessProfileData = async ({
  phoneNumberId,
  about,
  address,
  description,
  vertical,
  email,
  website1,
  website2,
  profilePicture
}) => {
  try {
    const response = await axiosInstance.post(
      'whatsapp_business_details/updateBusinessProfileData',
      {
        phoneNumberId,
        about,
        address,
        description,
        vertical,
        email,
        website1,
        website2,
        profilePicture
      }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const uploadProfilePhoto = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      'whatsapp_business_details/uploadProfilePicture',
      formdata
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getAccessToken = async (code) => {
  try {
    const response = await axiosInstance.get(
      `business_register/access?code=${code}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getBusinessInfo = async (wba_id, token) => {
  try {
    const response = await axiosInstance.get(
      `business_register/info?wba_id=${wba_id}&token=${token}`
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const BusinessApiCalls = {
  fetchBusinessProfileData,
  updateBusinessProfileData,
  uploadProfilePhoto,
  fetchNumberDisplayName,
  getAccessToken,
  getBusinessInfo
};
export default BusinessApiCalls;
